<template>
  <v-container fluid>
    <v-dialog v-model="showCobranzas" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="base white--text">Lista de Cobranzas</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="my-4">
          <v-card elevation="2">
            <v-data-table
              :headers="cobranzaHeader"
              :items="listaCobranza"
              no-data-text="No hay datos para mostrar"
            >
              <template
                v-slot:item.fechaCobranza="{ item }"
              >{{ formatDateTable(item.fechaCobranza) }}</template>
              <template v-slot:item.montoCobrado="{ item }">
                S/ {{
                item.montoCobrado.toFixed(2)
                }}
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text :disabled="isLoading" @click="showCobranzas = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex flex-column justify-center align-center">
      <p class="title black--text">Información del Usuario</p>
    </div>
    <div class="d-flex flex-column">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="5" class="d-flex align-center">
            <v-img src="@/assets/user_avatar.png" contain :aspect-ratio="16/9"></v-img>
          </v-col>
          <v-divider vertical v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
          <v-col cols="12" sm="12" md="6">
            <div
              v-for="(item, index) in dataUsuario"
              :key="index"
              class="d-flex flex-row justify-space-between mx-8"
            >
              <p class="font-weight-bold headline text-capitalize">{{index}}:</p>
              <p class="title" v-text="item"></p>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" sm="12">
            <p class="headline">Resumen de Ventas</p>
            <v-row>
              <v-col cols="12" sm="12" md="3" class="pb-0">
                <div class="d-flex justify-center justify-md-start">
                  <v-dialog
                    ref="dialogFechaResumen"
                    v-model="modalFechaResumen"
                    :return-value.sync="fechaResumen"
                    width="290px"
                    class="flex-shrink-0 flex-grow-0"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedFechaFormateada"
                        label="Fecha de Selección"
                        outlined
                        dense
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        class="flex-grow-0 flex-shrink-0"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="fechaResumen" scrollable locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalFechaResumen = false">Cancelar</v-btn>
                      <v-btn text color="primary" @click="actualizarFecha()">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="3"
                class="pb-0"
                v-if="this.getInfoUsuario.rol != 'Usuario'"
              >
                <div class="d-flex justify-center justify-md-start">
                  <v-select
                    v-model="selectedTrabajador"
                    label="Trabajador"
                    placeholder="Selecciona un Trabajador"
                    :items="computedUsuariosActivos"
                    outlined
                    dense
                    item-text="nombreTrabajador"
                    item-value="idTrabajador"
                    no-data-text="Cargando lista, espera..."
                    @click.native="fetchListaTrabajadores"
                    class="flex-grow-0 flex-shrink-0"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title v-text="data.item.nombreTrabajador"></v-list-item-title>
                        <v-list-item-subtitle v-text="data.item.rol"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <div class="d-flex justify-center justify-md-start">
                  <v-btn
                    class="success"
                    :disabled="!!!selectedTrabajador && getInfoUsuario.rol != 'Usuario'"
                    @click="consultarResumen"
                  >
                    <v-icon left>mdi-account-search</v-icon>Consultar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col sm="12" md="6" class="pt-0">
            <div v-if="fetchingResumen">
              <v-skeleton-loader class="mx-auto" type="table-tbody"></v-skeleton-loader>
            </div>
            <div v-else transition="slide-x-transition">
              <v-card class="mb-4">
                <v-card-title class="title">Asignado al {{formatDate(fechaActual)}}</v-card-title>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Producto</th>
                        <th class="text-left">Stock Asignado</th>
                        <th class="text-left">Stock Actual</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in resumenUsuario.resumenStockAsignado"
                        :key="item.idProducto"
                      >
                        <td>{{ item.nombreProducto }}</td>
                        <td>{{ item.stockAsignado ? item.stockAsignado : '-' }}</td>
                        <td class="text-left">{{ item.stockActual ? item.stockActual : '-' }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
              <v-card>
                <v-card-title class="title">Registrado el {{formatDate(fechaResumen)}}</v-card-title>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Producto</th>
                        <th class="text-left">Stock Asignado</th>
                        <th class="text-left">Stock Final</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in resumenUsuario.resumenInventarioRegistrado"
                        :key="item.idProducto"
                      >
                        <td>{{ item.nombreProducto }}</td>
                        <td>{{ item.stockProducto ? item.stockProducto : '-' }}</td>
                        <td
                          class="text-left"
                        >{{ item.stockDisponible ? item.stockDisponible : '-' }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </div>
          </v-col>
          <v-col sm="12" md="6" class="pt-0">
            <div v-if="fetchingResumen">
              <v-skeleton-loader class="mx-auto" type="table-tbody"></v-skeleton-loader>
            </div>
            <div v-else>
              <v-card class="mb-4">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Producto</th>
                        <th class="text-center">Tipo de Movimiento</th>
                        <th class="text-center">Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in resumenUsuario.resumenVentas"
                        :key="`${item.idProducto}-${item.tipoMovimiento}`"
                      >
                        <td>{{ item.nombreProducto }}</td>
                        <td class="text-center">{{ item.tipoMovimiento }}</td>
                        <td class="text-center">{{ item.cantidad }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>

              <v-card>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Tipo de Venta</th>
                        <th class="text-center">Total a Crédito</th>
                        <th class="text-center">Total al Contado</th>
                        <th class="text-center">Saldo Cobrado</th>
                        <th class="text-center">Total</th>
                      </tr>
                    </thead>
                    <tbody v-if="Object.keys(resumenUsuario).length">
                      <tr>
                        <td class="text-uppercase font-weight-bold">Contado</td>
                        <td>-</td>
                        <td
                          class="blue-grey lighten-5"
                        >{{ resumenUsuario.recaudacionContado.recaudacionContado ? 'S/ ' + resumenUsuario.recaudacionContado.recaudacionContado.toFixed(2) : '-' }}</td>
                        <td class="blue-grey lighten-5">-</td>
                        <td
                          class="blue-grey lighten-5"
                        >{{resumenUsuario.recaudacionContado.recaudacionContado ? 'S/ ' + resumenUsuario.recaudacionContado.recaudacionContado.toFixed(2) : '-'}}</td>
                      </tr>
                      <tr
                        style="cursor: pointer"
                        @click="mostrarCobranzas(resumenUsuario.listaCobranzas)"
                      >
                        <td class="text-uppercase font-weight-bold">Crédito</td>
                        <td>{{ resumenUsuario.recaudacionCredito.totalCredito ? 'S/ ' + resumenUsuario.recaudacionCredito.totalCredito.toFixed(2) : '-' }}</td>
                        <td
                          class="blue-grey lighten-5"
                        >{{ resumenUsuario.recaudacionCredito.totalContado ? 'S/ ' + resumenUsuario.recaudacionCredito.totalContado.toFixed(2) : '-' }}</td>
                        <td
                          class="blue-grey lighten-5"
                        >{{ resumenUsuario.cobranzas.totalMontoCobrado ? 'S/ ' + resumenUsuario.cobranzas.totalMontoCobrado.toFixed(2) : '-' }}</td>
                        <td
                          class="blue-grey lighten-5"
                        >S/ {{ (resumenUsuario.recaudacionCredito.totalContado + resumenUsuario.cobranzas.totalMontoCobrado).toFixed(2) }}</td>
                      </tr>
                      <tr>
                        <td class="text-uppercase font-weight-black">Total</td>
                        <td>{{ resumenUsuario.recaudacionCredito.totalCredito ? 'S/ ' + resumenUsuario.recaudacionCredito.totalCredito.toFixed(2) : '-' }}</td>
                        <td
                          class="blue-grey lighten-5"
                        >S/ {{ (resumenUsuario.recaudacionContado.recaudacionContado + resumenUsuario.recaudacionCredito.totalContado).toFixed(2) }}</td>
                        <td
                          class="blue-grey lighten-5"
                        >{{ resumenUsuario.cobranzas.totalMontoCobrado ? 'S/ ' + resumenUsuario.cobranzas.totalMontoCobrado.toFixed(2) : '-' }}</td>
                        <td
                          class="blue-grey lighten-4"
                        >S/ {{ (resumenUsuario.recaudacionCredito.totalContado + resumenUsuario.cobranzas.totalMontoCobrado + resumenUsuario.recaudacionContado.recaudacionContado).toFixed(2) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-divider></v-divider>
    <div class="d-flex flex-column ma-2 mt-4">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="title">Cambiar Información Personal</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form>
              <v-text-field
                v-for="(item, index) in dataUsuario"
                :key="index"
                :name="index"
                :rules="[rules.noEmpty]"
                :label="capitalize(index)"
                :value="item"
              ></v-text-field>
              <div class="d-flex justify-end">
                <v-btn color="green" dark>Actualizar</v-btn>
              </div>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="title">Cambiar Contraseña</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form>
              <v-text-field
                :append-icon="mostrarOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="mostrarOldPassword ? 'text' : 'password'"
                name="oldPassword"
                label="Contraseña Anterior"
                hint="Usa al menos 6 caracteres"
                class="input-group--focused"
                value
                @click:append="mostrarOldPassword = !mostrarOldPassword"
              ></v-text-field>
              <v-text-field
                :append-icon="mostrarNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="mostrarNewPassword ? 'text' : 'password'"
                name="newPassword"
                label="Contraseña Nueva"
                hint="Usa al menos 6 caracteres"
                class="input-group--focused"
                value
                @click:append="mostrarNewPassword = !mostrarNewPassword"
              ></v-text-field>
              <div class="d-flex justify-end">
                <v-btn color="green" dark>Actualizar</v-btn>
              </div>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      dataUsuario: {},
      mostrarOldPassword: true,
      mostrarNewPassword: true,
      rules: {
        required: value => !!value || 'El campo es requerido!',
        min: v => v.length >= 6 || 'Mínimo 6 caracteres',
        noEmpty: v => v.length > 0 || 'No se puede dejar el campo vacío'
      },
      resumenUsuario: {},
      fetchingResumen: false,
      modalFechaResumen: false,
      fechaResumen: new Date(new Date().setHours(-5))
        .toISOString()
        .substr(0, 10),
      fechaActual: new Date(new Date().setHours(-5))
        .toISOString()
        .substr(0, 10),
      selectedTrabajador: '',
      listaTrabajadores: [],
      listaCobranza: [],
      showCobranzas: false,
      cobranzaHeader: [
        { text: 'Venta #', value: 'idVenta', align: 'center' },
        { text: 'Cobranza #', value: 'idCobranza', align: 'center' },
        {
          text: 'Cobrador',
          value: 'encargadoCobranza',
          align: 'center'
        },
        { text: 'Cliente', value: 'nombreCliente', align: 'center' },
        {
          text: 'Monto Cobrado',
          value: 'montoCobrado',
          align: 'center'
        },
        {
          text: 'Fecha y Hora de Cobranza',
          value: 'fechaCobranza',
          align: 'center'
        }
      ]
    }
  },
  methods: {
    capitalize(text) {
      let arrText = text.split(' ')
      for (let i = 0; i < arrText.length; i++) {
        arrText[i] = arrText[i].charAt(0).toUpperCase() + arrText[i].slice(1)
      }
      return arrText.join(' ')
    },

    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    formatDateTable(date) {
      let parsedDate = new Date(parseInt(date))
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      return `${parsedDate.toLocaleDateString(
        'es-PE',
        options
      )} ${parsedDate.toLocaleTimeString()}`
    },

    actualizarFecha() {
      this.$refs.dialogFechaResumen.save(this.fechaResumen)
    },

    async fetchListaTrabajadores() {
      if (!this.listaTrabajadores.length) {
        this.listaTrabajadores = await this.fetchDataTrabajadores()
      }
    },

    async consultarResumen() {
      let idTrabajador =
        this.getInfoUsuario.rol != 'Usuario'
          ? this.selectedTrabajador
          : this.getInfoUsuario.idTrabajador

      this.fetchingResumen = true
      let fechaInicioRequest = new Date(new Date(this.fechaResumen)).setHours(
        24
      )
      let fechaFinRequest = new Date(this.fechaResumen).setHours(48)
      let payload = {
        idTrabajador,
        fechaInicioRequest,
        fechaFinRequest
      }

      let response = await this.fetchResumen(payload)
      this.resumenUsuario = response
      this.fetchingResumen = false
    },

    async mostrarCobranzas(listaCobranza) {
      this.listaCobranza = listaCobranza
      this.showCobranzas = true
    },

    ...mapActions('user', [
      'fetchInfoUsuario',
      'fetchResumen',
      'fetchDataTrabajadores'
    ])
  },

  computed: {
    computedFechaFormateada() {
      return this.formatDate(this.fechaResumen)
    },

    computedUsuariosActivos() {
      return this.listaTrabajadores.filter(item => item.activo == 1)
    },

    ...mapGetters('user', ['getInfoUsuario', 'isLoading'])
  },

  async mounted() {
    if (!Object.keys(this.getInfoUsuario).length) {
      await this.fetchInfoUsuario()
    }

    let {
      nombre,
      apellidos,
      contacto,
      fechaInicio,
      rol,
      nombreEstablecimiento,
      idTrabajador
    } = this.getInfoUsuario

    let options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }

    let parsedDate = new Date(parseInt(fechaInicio)).toLocaleDateString(
      'es-PE',
      options
    )

    if (rol == 'Usuario') {
      this.consultarResumen()
    }

    this.dataUsuario = {
      nombre,
      apellidos,
      contacto,
      'Fecha de Inicio': parsedDate,
      rol,
      Locación: nombreEstablecimiento
    }
  }
}
</script>

<style>
</style>